<template>
  <div id="index" class="pages">
    <!-- 赚积分 -->
    <img
      class="bg"
      alt="单倍"
      :src="'../../assets/luckdraw_new/hintPage.png?timestamp='+ new Date().getTime()"
    />
    <!-- 首页 -->
    <!-- 马上赚积分 -->
    <div class="jifen-d">
      <p class="jifen-li" @click="goSign()"></p>
      <p class="jifen-li" @click="goMyInfo()"></p>
      <p class="jifen-li" @click="goBuy()"></p>
    </div>
  </div>
</template>
<script>
var qs = require("qs");
import wx from "weixin-js-sdk";
export default {
  components: {
    wx,
  },
  data() {
    return {
      loded: false,
      imgList: "", //用户信息
      is_double: -1,
    };
  },
  created: function () {
    Object.assign(this.$data, this.$options.data());
    this.is_double = localStorage.getItem("is_double");
    if (location.href.indexOf("?") != -1) {
      var arr = location.href.split("?")[1];
      var arr1 = arr.split("&");
      for (var i = 0; i < arr1.length; i++) {
        if (arr1[i].split("=")[0] == "ut") {
          localStorage.setItem("ut", arr1[i].split("=")[1]);
          localStorage.setItem("userid", arr1[i].split("=")[1]);
        }
        if (arr1[i].split("=")[0] == "store_code") {
          if (arr1[i].split("=")[1].split("#")) {
            localStorage.setItem(
              "store_code",
              arr1[i].split("=")[1].split("#")[0]
            );
          } else {
            localStorage.setItem("store_code", arr1[i].split("=")[1]);
          }
        }
      }
    }
  },
  mounted: function () {},
  methods: {
    // 记log接口
    addLog(type) {
      var that = this;
      console.log("记log接口=", type);
      that
        .$axios({
          method: "post",
          url: that.$url + "/Api/Score/addLog",
          data: qs.stringify({
            ut: localStorage.getItem("ut"),
            type: type,
          }),
        })
        .then((res) => {});
    },
    // 签到
    goSign() {
      wx.miniProgram.switchTab({ url: "/pages/my/my?scene=2107050001895845" });
      this.addLog("去签到");
    },
    // 完善个人资料
    goMyInfo() {
      this.addLog("去完善信息");
      wx.miniProgram.navigateTo({
        url: "/packAgeB/pages/personal/personal?scene=2107050001898490",
      });
    },
    // 购物
    goBuy() {
      // 跳转到小程序首页
      this.addLog("去购物");
      wx.miniProgram.switchTab({ url: "/pages/index/index" });
    },
  },
};
</script>

<style scoped>
@import"../../assets/H5.css";
.jifen-li {
  margin-bottom: 33px;
  width: 630px;
  height: 80px;
}
.jifen-li:last-child {
  margin-bottom: 0;
}
.jifen-d {
  position: absolute;
  top: 710px;
  width: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pages {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.center {
  box-sizing: border-box;
  background: none;
  width: 93%;
  margin: 0 16px;
  margin-top: 811px;
  z-index: 3;
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 30px;
}
.time-d {
  width: 100%;
  height: 135px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 26px;
  color: #0d8371;
}
.time-d span:nth-child(2) {
  font-size: 32px;
  font-weight: bold;
  margin-top: 15px;
}
.red {
  color: #ed4700;
}
.center-d {
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.center-title {
  font-size: 32px;
  font-weight: bold;
}
.ul {
  position: relative;
  margin-top: 52px;
  box-sizing: border-box;
  width: 98%;
  border: 1px solid red;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.border {
  width: 80%;
  margin-left: 10%;
  height: 10px;
  background: #ff9c34;
  opacity: 0.7;
  border-radius: 3px;
  position: absolute;
  top: 45%;
}
.center-d .li {
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 26px;
  position: relative;
}
.text {
  position: absolute;
  left: -10px;
  bottom: 10px;
  width: 100%;
  color: #fff;
}
.center-d .li img {
  width: 145px;
}
.hint {
  margin: 43px auto 55px;
  color: #ed4700;
  font-weight: bold;
  font-size: 26px;
}
.btn {
  width: 85%;
  background: linear-gradient(0deg, #ff9117 0%, #ffae54 100%);
  color: #fff;
  line-height: 80px;
  border-radius: 8px;
  text-align: center;
  height: auto;
  font-size: 38px;
}
.center-bom {
  margin-top: 66px;
  width: 85%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  align-items: center;
  color: #444;
}
.center-bom p span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #444;
  margin-right: 5px;
  font-size: 24px;
}
.center-bom p {
  width: 50%;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.center-index {
  box-sizing: border-box;
  width: 100%;
  height: 500px;

  margin: 0 16px;
  margin-top: 811px;
  z-index: 3;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bg {
  width: 100%;
  height: auto;
}
.tips {
  margin-top: 55px;
  width: 620px;
  height: 313px;
}
</style>
